/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/html5shiv@3.7.3/dist/html5shiv.min.js
 * - /npm/uikit@3.3.0/dist/js/uikit.min.js
 * - /npm/uikit@3.3.0/dist/js/uikit-icons.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
